<template>
  <div
    class="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-2 px-0 d-flex justify-content-center"
  >
    <div
      class="position-absolute circle circle-lg rounded-circle box-shadow-1"
      :class="packageItem.type"
    >
      <div class="h1 position-absolute text-center font-medium-2 label-center">
        {{ packageItem.label }}
      </div>
    </div>
    <b-container
      class="pb-2 px-1 mx-1 mt-5 rounded box-shadow-1 d-flex flex-column justify-content-between align-items-center package-container"
    >
      <ValidationObserver ref="simpleRules">
        <h1 class="font-large-1 text-center text-white mt-5 pt-3">
          <div v-if="packageItem.type == 'silver'">
            <validation-provider
              #default="{ errors }"
              name="Logpoint"
              rules="required|min_value:1"
              >LP
              <input
                type="text"
                class="form-control mr-2 text-center"
                placeholder="Enter numbers only"
                aria-label="Enter numbers only"
                v-model="packageItem.value"
                @input="validateNumericInput"
                @blur="stopEditing"
                @keydown.enter="stopEditing"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </h1>
        <h1 class="font-medium-2 text-center text-white mt-1" v-if="packageItem.type == 'silver'">
          {{ packageItem.text }}
        </h1>
        <h1 class="font-medium-2 text-center text-white mt-5" v-else>
          {{ packageItem.text }}
        </h1>
        <div class="d-flex align-items-center justify-content-center">
          <b-button
            v-if="packageItem.type == 'silver'"
            @click="onBuy(packageItem.value)"
            variant="gradient-primary"
            class="box-shadow-1 px-5 mt-1"
            >Buy</b-button
          >
          
        </div>
      </ValidationObserver>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BLink,
  BCol,
  BContainer,
  BRow
} from 'bootstrap-vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    extend,
    required,
    BButton,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BLink,
    BRow
  },

  props: {
    packageItem: Object
  },
  data() {
    return {
      editing: false,
      amount: '',
      type: 'distributor'
    }
  },
  methods: {
    onBuy() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$http
            .post('pay_logpoints', { total_charge: this.packageItem.value })
            .then((response) => {
              location.href = response.data.url
            })
        }
      })
    },
    validateNumericInput() {
      this.packageItem.value = this.packageItem.value.replace(/[^0-9.]/g, '')
      const decimalCount = (this.packageItem.value.match(/\./g) || []).length
      if (decimalCount > 1) {
        this.packageItem.value = this.packageItem.value.replace(/\./g, '')
      }
    },
    startEditing() {
      this.editing = true
    },
    stopEditing() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.editing = false
        }
      })
    }
  },

  mounted() {}
}
</script>

<style scoped>
.package-container {
  background: #2b3f46;
  margin-top: 10px;
}

.bttn-container {
  height: 20%;
}

.gold {
  background: #ffb947;
  padding: 5rem;
  /* box-shadow: 0px 10px 4px #000000; */
}

.label-center {
  right: 0;
  top: 60px;
  width: 140px;
}

.silver {
  background: #dfe6ed;
  padding: 5rem;
}

.platinum {
  background: #5daab4;
  padding: 5rem;
  /* box-shadow: 0px 10px 4px #000000; */
}
</style>
